/*
 * SPDX-FileCopyrightText: 2022 Benjamin Kahlau
 *
 * SPDX-License-Identifier: GPL-3.0-or-later
 */

@import url('../node_modules/highlight.js/styles/base16/dracula.css');
@import url('../node_modules/devicon/devicon.min.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
    @apply mb-2;
    @apply mt-4;
    @apply font-bold;
    @apply text-red-600;
}

article h1 {
    @apply text-2xl;
}

article h2 {
    @apply text-xl;
}

article h1:first-child {
    @apply mt-0;
}

article a {
    @apply underline;
    @apply decoration-dotted;
}

article a:not(.no-icon)[href*="//"]:after
{
    content: ' '
        url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNqEkIEJwCAMBGPpEs7hHK6hY+gauoZr6Bo6RtqEWkxb6EMQnuM/USEirIoxSmOKwDkhBEwp4VPkb4801Xun9xW4e+9FldYarLUvkBPPOp4JGWOgtSaSt6VWQKUU9nPOEiSIgAmNMdadFTjn7utqrXwh6fLvH9nXhamW5ksMnpfDnw4BBgBfunO056MmqAAAAABJRU5ErkJggg==');
}

article h1 a,
article h2 a,
article h3 a,
article h4 a,
article h5 a,
article h6 a{
    @apply no-underline;
}

article h1 a[href*="//"]:after
{
    content: none !important;
}

article figure {
    @apply flex;
    @apply flex-col;
    @apply mb-4;
    @apply border;
    @apply border-gray-200;
}

article figure td.code {
    @apply p-0;
}

article figure img {
    @apply mb-0;
}

article figure figcaption,
article pre .caption {
    @apply italic;
    @apply bg-neutral-800;
    @apply text-white;
    @apply px-4;
    @apply py-2;
}

article figure.highlight figcaption {
    @apply flex;

    justify-content: space-between;
}

article ul,
article ol {
    @apply ml-4;
    @apply list-disc;
}

article blockquote {
    @apply relative;
    @apply p-4;
    @apply border-l-4;
    @apply border-red-600;
    @apply bg-neutral-200;
    @apply dark:bg-neutral-700;
}

article blockquote cite {
    @apply ml-1;
}

article hr.footnotes-sep {
    @apply mt-2;
    @apply pt-2;
    @apply border-t-2;
    @apply border-slate-200;
}

/* Keyboard codes */
article kbd {
    @apply font-bold;
    @apply rounded;
    @apply border;
    @apply border-black;

    padding: 1px 2px 0;
}

article kbd > kbd,
article samp > kbd {
    @apply border-0;
}

/* Promts */
article samp > kbd::before {
    @apply text-red-600;

    content: 'benjamin@roanapur:~$ ';
}

/* Details/Spoiler */
details > summary {
    @apply cursor-pointer;
}

details > summary::marker {
    @apply text-red-600;
}

/* Menus */
article kbd > samp {
    @apply italic;
}

article kbd + kbd > samp::before {
    content: ' > ';
}

/* Taken from hljs */
article pre code {
    @apply block;
    @apply p-1;

    color: #e9e9f4;
    background: #282936;
    overflow-x: auto;
}

article p:not(:last-child),
article img:not(:last-child),
article blockquote:not(:last-child),
article pre:not(:last-child),
article details:not(:last-child),
article ul:not(:last-child),
article ol:not(:last-child) {
    @apply mb-4;
}

article  p {
    margin-bottom: 0 !important;
}

/*
https://hexo.io/docs/helpers.html#paginator
 */
.page-number,
.extend.prev,
.extend.next {
    @apply py-1;
    @apply px-2;
    @apply font-bold;
    @apply border-2;
}

.page-number.current {
    @apply text-gray-400;
}

/*
https://hexo.io/docs/helpers.html#list-tags
https://hexo.io/docs/helpers.html#list-categories
 */
ul.tag-list,
ul.category-list {
    @apply ml-0;
    @apply mb-2;
}

ul.tag-list li.tag-list-item,
ul.category-list li.category-list-item {
    @apply inline-block;
    @apply mr-1;
}

ul.tag-list li.tag-list-item a,
ul.category-list li.category-list-item a {
    @apply italic;
}

/*
https://hexo.io/docs/helpers.html#toc
 */
ol.toc {
    @apply ml-0;
    @apply mb-2;
    @apply p-2;
    @apply border-2;
}

ol.toc,
ol.toc-child {
    @apply list-none;
}

ol.toc-child {
    @apply ml-4;
    @apply mb-1;
}
